import React from 'react';
import {Col, Form} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import {truncateDateToDay} from "../../../utils/Util";

interface Props {
  specificDate: boolean;
  setSpecificDate: (value: any) => void;
  startDate: Date | undefined;
  setStartDate: (value: Date) => void;
}

const SpecificStartDateForm = (props: Props) => {
  return (
      <Col>
        <Form.Group className="form-element">
          <Form.Label><small>Start date in mind?</small></Form.Label>
          <div className={"d-inline-block ml-2"}>
            <Form.Check
                inline
                label="Yes"
                name="specificDateToggle"
                type="radio"
                id="yes"
                onChange={() => props.setSpecificDate(true)}
                checked={props.specificDate}
            />
            <Form.Check
                inline
                label="No"
                name="specificDateToggle"
                type="radio"
                id="no"
                onChange={() => props.setSpecificDate(false)}
                checked={!props.specificDate}
            />
          </div>

          {!props.specificDate && (
                <div className={"mt-0"}>
                  <small>We'll select a future date for your trip.</small>
                </div>
          )}

          {props.specificDate && (
              <div>
                <Form.Label><small>Select a date: </small></Form.Label>
                <DatePicker
                    minDate={new Date()}
                    className={"ml-2"}
                    onChange={(date) => props.setStartDate(truncateDateToDay(date as Date))}
                    value={props.startDate}
                />
              </div>
          )}

        </Form.Group>

      </Col>
    );
};

export default SpecificStartDateForm;