import * as React from "react";
import {Link} from "react-router-dom";

function Logo() {
  return (
      <Link to={`/`} style={{ textDecoration: "none"}}>
      <div>
        <div className={"logo"}>
            <span className={"dm-serif-text-regular"}>TripGuru</span>
        </div>
        <div className={"logo-motto"}>
          <span className={"dm-serif-text-regular"}>Your personal travel agent</span>
        </div>
      </div>
      </Link>
  )
}

export default Logo;