import {BuiltTripTravelDetails} from "../../../../types/Types";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {formatDateString} from "../../../../utils/Util";

interface Props {
  journey: BuiltTripTravelDetails
  finalJourney: boolean
}

function TravelInfo(props: Props) {
  const journey = props.journey;
  return (
      <>
        {journey.display && <div className={`travel-details ${props.finalJourney ? "" : "mb-3"}`}>
            <div>
                <div><b>{journey.from}</b> to</div>
                <div><b>{journey.to}</b></div>
                <div>{formatDateString(journey.date)} {journey.isReturnJourney && <span>to {formatDateString(journey.returnJourneyDate)}</span>}</div>
                <div><FontAwesomeIcon icon={faArrowRight} /> {journey.departureTime} {journey.departureTimeZone} to {journey.arrivalTime} {journey.arrivalTimeZone}</div>
                {journey.isReturnJourney && <div><FontAwesomeIcon icon={faArrowLeft} /> {journey.returnDepartureTime} {journey.returnDepartureTimeZone} to {journey.returnArrivalTime} {journey.returnArrivalTimeZone}</div>}
                <div className={"mt-1"}>
                    {journey.isReturnJourney &&<div className={"tag bg-gray"}>Return</div>}
                    {!journey.isReturnJourney &&<div className={"tag bg-gray"}>One Way</div>}
                    <div className={"tag bg-blue"}>{journey.journeyTime} Hours</div>
                    <div className={"tag bg-orange"}>${journey.averageCost}</div>
                </div>
            </div>
            <div style={{float: "right", marginTop: "-28px"}} className={"book-button"}>Reserve <FontAwesomeIcon icon={faUpRightFromSquare}/></div>
        </div>}
      </>
  )
}

export default TravelInfo;