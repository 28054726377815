import * as React from "react";
import '../../../App.css';
import {useState} from "react";
import {httpsCallable} from "@firebase/functions";
import {functions} from "../../../firebase/FirebaseProvider";
import {TripGenerationResponse} from "../../../types/Types";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import SpecificStartDateForm from "./SpecificStartDateForm";
import {addTwoMonths, formatDateWithSuffix} from "../../../utils/Util";
import history from "../../../utils/History";

interface Props {
  setBuiltTripId: (value: any) => void;
}

function TripInputForm(props: Props) {
  const [isBuildingTrip, setIsBuildingTrip] = useState<boolean>(false);

  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const [duration, setDuration] = useState<number>(2);
  const [budget, setBudget] = useState<string>("standard");
  const [activityLevel, setActivityLevel] = useState<string>("standard, some activities with a little travelling");
  const [specificDate, setSpecificDate] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(addTwoMonths(new Date()));

  const buildTrip = (): void => {
    if(from === "" || to === "") {
      alert("Please fill in your current location and desired destination.");
      return;
    }

    setIsBuildingTrip(true);
    const buildTripRequest = httpsCallable(functions, 'buildTripRequest');
    buildTripRequest({
      prompt: `I want you to organise me a trip from ${from} to ${to} for ${duration+1} days. 
      The trip should begin on exactly ${specificDate ? formatDateWithSuffix(startDate) : formatDateWithSuffix(addTwoMonths(new Date()))}. 
      My budget for the trip is (i.e. budget: 2 star, standard: 3-4 star, luxury: 5 star): ${budget}.
      My activity level for the trip is: ${activityLevel}.`,
      locationFrom: from || "London",
      locationTo: to || "Madrid",
      days: duration+1,
      budget: budget,
      activityLevel: activityLevel,
      startDate: startDate,
    }).then(r => {
      const tripId = (r.data as TripGenerationResponse).response;
      props.setBuiltTripId(tripId);
      history.push(`/trip-builder/${tripId}`);
    });
  };

  return (
    <div>
        <Form>
          <div className="form-row">
            <Col>
              <Form.Group className="form-element" controlId="fromLocation">
                <Form.Label><small>Departing from?</small></Form.Label>
                <Form.Control
                    type="text"
                    placeholder="For example: London"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    required
                />
              </Form.Group>
            </Col>
          </div>
          <div className="form-row">
            <Col>
              <Form.Group className="form-element" controlId="toLocation">
                <Form.Label><small>Where do you want to go?</small></Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Type a place, city or country"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    required
                />
              </Form.Group>
            </Col>
          </div>
          <div className="form-row">
            <Col>
              <Form.Group className="form-element" controlId="duration">
                <Form.Label><small>Length of trip? {duration} nights / {duration+1} days</small></Form.Label>
                <Form.Control type="range" min="2" max="20" value={duration}
                              onChange={(e) => setDuration(+e.target.value)} className="form-range" />
              </Form.Group>
            </Col>
          </div>
          <div className={"form-row"}>
            <Col style={{marginRight: "0px"}}>
              <Form.Group className="form-element" controlId="budget">
                <Form.Label><small>Activity levels?</small></Form.Label>
                <Form.Select
                    aria-label="Activity"
                    onChange={(e) => setActivityLevel(e.target.value)}
                    defaultValue="standard, some activities with a little travelling"
                >
                  <option value="relaxed, few activities with minimal amount of travelling">Relaxed</option>
                  <option value="standard, some activities with a little travelling">Standard</option>
                  <option value="busy, lots of activities with a lot of travelling">Active</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col style={{marginLeft: "0px"}}>
              <Form.Group className="form-element" controlId="budget">
                <Form.Label><small>Spending levels?</small></Form.Label>
                <Form.Select
                    aria-label="Budget"
                    onChange={(e) => setBudget(e.target.value)}
                    defaultValue="standard"
                >
                  <option value="budget">Budget</option>
                  <option value="standard">Standard</option>
                  <option value="luxury">Luxury</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </div>
          <div className={"form-row"}>
            <SpecificStartDateForm specificDate={specificDate} setSpecificDate={setSpecificDate} startDate={startDate} setStartDate={setStartDate} />
          </div>

          <div className={"form-row mt-3"}>
            <Col>
              <div className={"float-right"}>
                <div className={"click-button"} onClick={() => buildTrip()}>
                  {!isBuildingTrip && <span>Build my trip</span>}
                  {isBuildingTrip && <span>Now building...</span>}
                </div>
              </div>
            </Col>
          </div>
        </Form>
      </div>
  );
}

export default TripInputForm;