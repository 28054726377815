import React from "react";
import '../../../App.css';
import {faArrowRotateRight, faGear, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function LoadingWheel() {
  return (
      <div className="loading-wheel">
        <FontAwesomeIcon className="spin" icon={faGear}/>
      </div>
  )
}

export default LoadingWheel