import {BuiltTripTravelDetails} from "../../../../types/Types";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBus, faTrain, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import TravelInfo from "./TravelInfo";

interface Props {
  buses: Array<BuiltTripTravelDetails>
}

function Buses(props: Props) {
  return (
      <>
        {props.buses.length > 0 && <div className={"mt-3 p-3 day-bg border-radius lined-paper"}>
          <div className={"day-info d-inline-block mb-3"}><b>Buses</b> <FontAwesomeIcon icon={faBus} /></div>
          {props.buses.map((journey, index) => (
              <TravelInfo journey={journey} finalJourney={index == props.buses.length - 1} />
          ))}
        </div>}
      </>
  )
}

export default Buses;