import * as React from "react";
import '../../App.css';
import {Navbar} from "react-bootstrap";
import Logo from "./Logo";
import Navigation from "./Navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {getAuth, signOut} from "firebase/auth";
import history from "./../../utils/History";
import {CurrentUser} from "../../types/Types";

interface Props {
  currentUser: CurrentUser | undefined;
  setCurrentUser: (currentUser: CurrentUser | undefined) => void
}

function Header(props: Props) {

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        props.setCurrentUser(undefined);
        history.push("/");
      });
  };

  return (
      <>
        <Navbar className="navbar navbar-expand-lg navbar-dark bg-blue pb-0 pt-0 pl-2 pr-2">
          <div className="container-fluid">
            <Logo />
          </div>
          <div className="ml-auto">
            {props.currentUser && <div className={"logout-button"} onClick={() => logout()}>
              <FontAwesomeIcon icon={faSignOutAlt}/>
            </div>}
          </div>
        </Navbar>
        <Navigation currentUser={props.currentUser} />
      </>
  );
}

export default Header;