import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";

const Stars = ({ count }: { count: number }) => {
  return (
      <div className={"d-inline-block"}>
        {Array.from({ length: count }).map((_, index) => (
            <FontAwesomeIcon icon={faStar} />
        ))}
      </div>
  );
};

export default Stars;
