import React from "react";
import "./../../App.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import {CurrentUser} from "../../types/Types";

interface Props {
  currentUser: CurrentUser | undefined;
}

function Navigation(props: Props) {
    return (
      <div className={"mb-3 mt-2"}>
        <Nav variant="pills" className={"ml-2 mr-2"}>
          <>
            <NavLink className="nav-link" to="/create">
                Create
            </NavLink>
            <NavLink className="nav-link" to="/explore">
                Explore
            </NavLink>
            {!props.currentUser && <NavLink className="ml-auto nav-link mr-0" to="/login">
                Login
            </NavLink>}
            {props.currentUser && <NavLink className="ml-auto nav-link mr-0" to="/trips">
                My Trips
            </NavLink>}
          </>
        </Nav>
      </div>
    );
}

export default Navigation;
