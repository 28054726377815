export const truncateDateToDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const formatDateWithSuffix = (date: Date) => {
  const day = date.getDate();
  const monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th');
  return `${dayWithSuffix} of ${month} ${year}`;
};

export const addTwoMonths = (date: Date): Date => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + 2);
  return result;
};

export const convertTimestamp = (timestamp: any): string => {
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

export const convertTimestampWithDay = (timestamp: any): string => {
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', year: '2-digit', month: 'short' };
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
  return formattedDate;
};

export const convertTimestampToTime = (timestamp: any): string => {
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
  return formattedDate;
};

export const calcSecondsBetween = (timestamp1: any, timestamp2: any): string => {
  const date1 = timestamp1.toDate(); const date2 = timestamp2.toDate();
  const differenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  const differenceInSeconds = differenceInMilliseconds / 1000;
  return differenceInSeconds.toFixed(0);
};

export const capitalizeAllWords = (input: string): string => {
  return input.replace(/\b\w/g, char => char.toUpperCase());
};

export const formatDateString = (dateString: string): string => {
  // Create a new Date object
  const date = new Date(dateString);

  // Define month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Determine the ordinal suffix for the day
  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Combine into desired format
  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;

  return formattedDate;
};