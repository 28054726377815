import {BuiltTrip} from "../../../../types/Types";
import * as React from "react";
import {capitalizeAllWords, convertTimestamp} from "../../../../utils/Util";
import {Card} from "react-bootstrap";

interface Props {
  trip: BuiltTrip
}

function TripTitle(props: Props) {
  return (
      <Card className={"mt-2 mb-3 border-radius title"}>
        <div className={"p-3"}>
          {capitalizeAllWords(props.trip.locationFrom)} to {capitalizeAllWords(props.trip.locationTo)} - {convertTimestamp(props.trip.startDate)}
        </div>
      </Card>
  )
}

export default TripTitle;