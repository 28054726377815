import * as React from "react";
import './../App.css';
import {Col, Form, Row} from "react-bootstrap";
import history from "./../utils/History";
import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailAndPassword} from "@firebase/auth";
import { getDocs, where, query, collection } from "@firebase/firestore";
import {db} from "../firebase/FirebaseProvider";

function RegisterPage() {
  const [email, setEmail] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        history.push('/');
        return;
      }
    });
  });

  const loginWithEmail = async () => {
    const userCollection = query(collection(db, 'users'), where('email', '==', email.toLowerCase()));
    const userCollectionSnap = await getDocs(userCollection);
    if(userCollectionSnap.size == 1) {
      setError(true);
      setFeedback("You have an existing user profile, please login.");
      return;
    }

    const actionCodeSettings = {
      url: window.location + '/auth/response',
      handleCodeInApp: true
    };
    const auth = getAuth();
    try {
      await sendSignInLinkToEmail(auth, email.toLowerCase(), actionCodeSettings)
        .then(() => {
          window.localStorage.setItem(
              'emailForSignIn',
              email.toLowerCase(),
          );
          setError(false);
          setFeedback("A registration link has been emailed to you.")
        }).catch((error) => {
          setError(true);
          setFeedback("Incorrect credentials, please try again.");
        });
    } catch(e) {
      setError(true);
      setFeedback("Incorrect credentials, please try again.");
    }
  };

  return (
    <>
      {feedback && <div className={`message-box mb-2 alert ${error ? "alert-danger" : "alert-success"}`}>
        <div>{feedback}</div>
      </div>}
      <Form className={"mb-3"}>
        <div className="form-row">
          <Col>
            <Form.Group className="form-element" controlId="email">
              <Form.Label>
                <small><b>Email</b></small>
              </Form.Label>
              <Form.Control
                  type="email"
                  name={"email"}
                  placeholder=""
                  value={email}
                  autoComplete={"username"}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
            </Form.Group>
          </Col>
        </div>
        <div className={"form-row mt-3"}>
          <Col>
            <div className={"float-right"}>
              <div className={"click-button"} onClick={() => loginWithEmail()}>
                <span>Register</span>
              </div>
            </div>
          </Col>
        </div>
      </Form>
    </>
  );
}

export default RegisterPage;