import {BuiltTripTravelDetails} from "../../../../types/Types";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TravelInfo from "./TravelInfo";
import {faPlaneUp} from "@fortawesome/free-solid-svg-icons";

interface Props {
  flights: Array<BuiltTripTravelDetails>
}

function Flights(props: Props) {
  return (
      <>
        {props.flights.length > 0 && <div className={"mt-3 p-3 day-bg border-radius lined-paper"}>
          <div className={"day-info d-inline-block mb-3"}><b>Flights</b> <FontAwesomeIcon icon={faPlaneUp} /></div>
          {props.flights.map((journey, index) => (
              <TravelInfo journey={journey} finalJourney={index == props.flights.length - 1}/>
          ))}
        </div>}
      </>
  )
}

export default Flights;