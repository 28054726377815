import React from "react";
import {Card, Container} from "react-bootstrap";
import LoadingWheel from "./LoadingWheel";

interface Props {
  tripLoading: boolean
}

function TripLoadingPage(props: Props) {
  return (
      <div className={`border-radius ${props.tripLoading ? "mt-2" : ""}`}>
        <Card className={"trip-loading border-radius"}>
          <div>We're now building your trip. This may take a few minutes as we gather all the necessary information.</div>
          <div>
            <LoadingWheel />
          </div>
          <div>Feel free to leave this page and come back in a short while, your trip will be waiting for you.</div>
        </Card>
      </div>
  )
}

export default TripLoadingPage;