import {BuiltTripTravelDetails} from "../../../../types/Types";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHotel, faTrain, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {formatDateString} from "../../../../utils/Util";
import TravelInfo from "./TravelInfo";

interface Props {
  trains: Array<BuiltTripTravelDetails>
}

function Trains(props: Props) {
  return (
      <>
        {props.trains.length > 0 && <div className={"mt-3 p-3 day-bg border-radius lined-paper"}>
          <div className={"day-info d-inline-block mb-3"}><b>Trains</b> <FontAwesomeIcon icon={faTrain} /></div>
          {props.trains.map((journey, index) => (
              <TravelInfo journey={journey} finalJourney={index == props.trains.length - 1}/>
          ))}
        </div>}
      </>
  )
}

export default Trains;