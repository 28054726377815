import {BuiltTripActivity} from "../../../../types/Types";
import * as React from "react";
import {faUtensils} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  restaurant: BuiltTripActivity
}

function Restaurant(props: Props) {
  const restaurant = props.restaurant;
  return (
    <div className={`unit-box activity restaurant`}>
      <div className="time itim-regular">{restaurant.visitingTime}</div>
      <div className="text-lines time-border">
        <div>
          <span><FontAwesomeIcon icon={faUtensils} className={"mr-1"}/></span>
          <span><b>{restaurant.name}</b></span>
          <div className={"d-inline-block ml-1"}>
            {restaurant.type && <div className={"tag bg-gray-2"}>{restaurant.type}</div>}
          </div>
        </div>
        <div>{restaurant.description}</div>
        <div>{restaurant.address}</div>
      </div>
    </div>
  )
}

export default Restaurant;