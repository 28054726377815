import './App.css';
import * as React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import TripBuilderPage from "./components/builder/TripBuilderPage";
import {Container} from "react-bootstrap";
import Header from "./components/header/Header";
import {Route, Router, Switch} from "react-router";
import TripResponsePage from "./components/builder/display/TripResponsePage";
import history from "./utils/History";
import RecentTripsPage from "./pages/RecentTripsPage";
import LoginPage from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import {
  serverTimestamp,
  doc, setDoc, getDocs, where,
  query, collection, onSnapshot,
} from "@firebase/firestore";
import {db} from "./firebase/FirebaseProvider";
import {CurrentUser} from "./types/Types";
import TripsPage from "./pages/TripsPage";
import RegisterPage from "./pages/RegisterPage";
import SetPassword from "./pages/SetPassword";
import RegisterResponse from "./pages/RegisterResponse";

function App() {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (userCredential: any | undefined) => {
      if (userCredential && !currentUser) {
        const docRef = doc(db, 'users', userCredential.uid);
        await updateUser(docRef, userCredential.uid, userCredential.email);
        const userCollection = query(
            collection(db, 'users'),
            where('id', '==', userCredential.uid),
        );
        const userCollectionSnap = await getDocs(userCollection);
        await userCollectionSnap.forEach((userDoc) => {
          onSnapshot(doc(db, 'users', userDoc.id), (doc) => {
            if (doc.exists() && !currentUser) {
              setCurrentUser(doc.data() as CurrentUser);
            }
          });
        });
      }
    });
  });

  async function updateUser(docRef: any, userId: string, email: string): Promise<void> {
    try {
      const data = {
        lastSeenAt: serverTimestamp(),
        id: userId,
        email: email,
      };
      await setDoc(docRef, data, { merge: true });
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  return (
      <Router history={history}>
        <Container className={"mt-2 mb-3"}>
          <Route render={(props) => <Header {...props} currentUser={currentUser} setCurrentUser={setCurrentUser} /> }/>
          <div className={"mr-2 ml-2"}>
            <Switch>
              {/*No auth required*/}
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/login/auth/response" render={(props) => <RegisterResponse {...props} redirectTo={""} /> }/>
              <Route exact path="/register/auth/response" render={(props) => <RegisterResponse {...props} redirectTo={"password"} /> }/>
              <Route exact path="/create" component={TripBuilderPage} />
              <Route exact path="/trip-builder/:id" component={TripResponsePage} />
              <Route exact path="/explore" component={RecentTripsPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/password" component={SetPassword} />

              {/*Auth Required*/}
              {currentUser && <Route exact path="/trips" render={(props) => <TripsPage />} />}
              {/*<Route exact path="/support" render={(props) => <Support {...props} settings={settings} currentUser={currentUser} />} />*/}
            </Switch>
          </div>
        </Container>
      </Router>
  );
}

export default App;
