import * as React from "react";
import '../../../App.css';
import {BuiltTrip} from "../../../types/Types";
import {useEffect} from "react";
import {collection, query, where, onSnapshot} from "@firebase/firestore";
import {db} from "../../../firebase/FirebaseProvider";
import {useState} from "react";
import TripResponseView from "./TripResponseView";
import TripLoadingPage from "../loading/TripLoadingPage";
import TripTitle from "./itinerary/TripTitle";

interface Props {
  match: {
    params: {
      id: string
    }
  },
}

function TripResponsePage(props: Props) {
  const [builtTrip, setBuiltTrip] = useState<BuiltTrip | undefined>(undefined);

  const builtTripId = props.match.params.id;

  useEffect(() => {
    onSnapshot(query(collection(db, 'trip-requests'),
        where("id", "==", builtTripId),
    ), (snapshot) => {
      snapshot.docChanges().forEach(tripRequest => {
        if (tripRequest.type === 'added' || tripRequest.type === 'modified') {
          setBuiltTrip(tripRequest.doc.data() as BuiltTrip);
        }
      });
    });
  }, [builtTripId]);
  
  return (
      <>
        <div className={"p-0 mb-3"}>
          {builtTrip && <div className={"mb-2 border-radius"}>
            <TripTitle trip={builtTrip}/>
          </div>}

          {(!builtTrip || (builtTrip && builtTrip.state === 'PENDING' && !builtTrip.error)) && <TripLoadingPage tripLoading={!builtTrip}/>}
          {(builtTrip && builtTrip.state !== 'PENDING') && <TripResponseView builtTrip={builtTrip} />}
          {(builtTrip && builtTrip.error) && <div className={"message-box"}>Sorry, an error has occurred, please try creating a new trip.</div>}
        </div>
      </>
  );
}

export default TripResponsePage;