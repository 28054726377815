import {BuiltTripHotel, BuiltTripItineraryUnit, BuiltTripTravelDetails} from "../../../../types/Types";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight, faBus, faLandmark,
  faPlaneArrival,
  faPlaneDeparture, faPlaneUp,
  faStar, faTrain,
  faUtensils
} from "@fortawesome/free-solid-svg-icons";
import {capitalizeAllWords} from "../../../../utils/Util";

interface Props {
  unit: BuiltTripItineraryUnit
}

function TravelDetails(props: Props) {
  const unit = props.unit;

  const getTransportIcon = (travelType: string) => {
    if(travelType === 'FLIGHT') {
      return <FontAwesomeIcon icon={faPlaneUp} className={"mr-1"}/>
    } else if(travelType === 'TRAIN') {
      return <FontAwesomeIcon icon={faTrain} className={"mr-1"}/>
    } else if(travelType === 'BUS') {
      return <FontAwesomeIcon icon={faBus} className={"mr-1"}/>
    } else {
      return <></>
    }
  };

  return (
      <>
        {unit.startingPlace !== unit.endingPlace && <div>
          <div className={"travel-details unit-box"}>
            <div className="time-no-flex itim-regular">
              <div style={{textAlign: "center"}}>
                <div>{unit.travelDetails.departureTime}</div>
                <div>to</div>
                <div>{unit.travelDetails.arrivalTime}</div>
              </div>
            </div>
            <div className="text-lines time-border">
              <div>
                <b>{getTransportIcon(unit.travelDetails.travelType)}{capitalizeAllWords(unit.travelDetails.travelType.toLowerCase())}</b>
              </div>
              <div>
                {unit.travelDetails.travelType === "FLIGHT" && <>
                    <div>{unit.travelDetails.from} <FontAwesomeIcon icon={faPlaneDeparture}/></div>
                    <div>{unit.travelDetails.to} <FontAwesomeIcon icon={faPlaneArrival}/></div>
                </>}
                {(unit.travelDetails.travelType === "TRAIN" || unit.travelDetails.travelType === "BUS" || unit.travelDetails.travelType === "METRO") && <>
                    <div>{unit.travelDetails.from} <FontAwesomeIcon icon={faArrowRight}/></div>
                    <div>{unit.travelDetails.to} <FontAwesomeIcon icon={faArrowLeft}/></div>
                </>}
              </div>
            </div>
          </div>
        </div>}
      </>
  )
}

export default TravelDetails;