import React, {useState} from "react";
import {getAuth, updatePassword} from "@firebase/auth";
import {Col, Form} from "react-bootstrap";

function SetPassword() {
    const [password, setPassword] = useState<string>("");
    const [feedback, setFeedback] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const savePassword = async () => {
        console.log("test");
        if(password.length < 6) {
            setError(true);
            setFeedback("Your password must contain 6 or more characters.")
        }
        const auth = getAuth();
        const user = auth.currentUser;
        console.log(auth);
        console.log(user);
        if(user && password) {
            updatePassword(user, password).then(() => {
                setError(false);
                setFeedback("Password successfully saved.")
            }).catch((error) => {
                setError(true);
                if(error.message.includes("requires-recent-login")) {
                    setFeedback("To set a password you need to have logged in more recently, please log out and log back in, and then try again.");
                } else {
                    setFeedback(error.message);
                }
            });
        }
    };

    return (
        <>
            <div>
                {feedback && <div className={`message-box mb-2 alert ${error ? "alert-danger" : "alert-success"}`}>
                    <div>{feedback}</div>
                </div>}
                <Form>
                    <div className="form-row">
                        <Col>
                            <Form.Group className="form-element" controlId="password">
                                <Form.Label>
                                    <small>
                                        <b>Set your password</b>
                                    </small></Form.Label>
                                <Form.Control
                                    type="password"
                                    name={"password"}
                                    placeholder=""
                                    value={password}
                                    autoComplete={"current-password"}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </div>
                    <div className={"form-row mt-3"} >
                        <Col>
                            <div className={"float-right"}>
                                <div className={"click-button"} onClick={() => savePassword()}>
                                    <span>Set password</span>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default SetPassword;
