import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD6GsthnDtfGYfdLP3w76cfpU9icPm3lug",
  authDomain: "trippy-8fe76.firebaseapp.com",
  projectId: "trippy-8fe76",
  storageBucket: "trippy-8fe76.firebasestorage.app",
  messagingSenderId: "985132392075",
  appId: "1:985132392075:web:f72cef31262aa424dafe8e",
  measurementId: "G-HFTFXT88RG"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west2");

export {db, functions}
