import * as React from "react";
import '../../App.css';
import TripInputForm from "./form/TripInputForm";
import {useEffect, useState} from "react";
import {BuiltTrip} from "../../types/Types";
import TripResponseView from "./display/TripResponseView";

function TripBuilderPage() {
  const [builtTripId, setBuiltTripId] = useState<string>("");
  const [builtTrip, setBuiltTrip] = useState<BuiltTrip | undefined>(undefined);

  return (
      <>
        {!builtTrip && !builtTripId && <TripInputForm setBuiltTripId={setBuiltTripId} />}
        {builtTrip && <TripResponseView builtTrip={builtTrip}/>}
      </>
  );
}

export default TripBuilderPage;