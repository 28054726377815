import * as React from "react";
import '../../../App.css';
import {Card} from "react-bootstrap";
import {BuiltTrip, BuiltTripItineraryUnit} from "../../../types/Types";
import Hotel from "./itinerary/Hotel";
import TravelDetails from "./itinerary/TravelDetails";
import {formatDateString} from "../../../utils/Util";
import Restaurant from "./itinerary/Restaurant";
import Activity from "./itinerary/Activity";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import Flights from "./details/Flights";
import Hotels from "./details/Hotels";
import Trains from "./details/Trains";
import Buses from "./details/Buses";

interface Props {
  builtTrip: BuiltTrip;
}

function  TripResponseView(props: Props) {
  const trip = props.builtTrip.response;

  const sortedActivities = (unit: BuiltTripItineraryUnit) => {
    return [...unit.hotels, ...unit.restaurants, ...unit.activities]
        .sort((a, b) => (
            extractHours(a.visitingTime || "00:00") - extractHours(b.visitingTime || "00:00")
        ))
  };

  const extractHours = (time: string): number => {
    const [hour, _] = time.split(':');
    return parseInt(hour.replace(/^0+/, ''));
  };

  return (
      <>

        <div className={"list-bg mb-3"}>
          <div className={"message-box"}>
            <b>Trip Details</b>
            <div className={"float-right"}><FontAwesomeIcon icon={faChevronDown}/></div>
          </div>
          <div className={"message-box mb-0"}>
            <div>{trip.tripDescription}</div>
            <div className={"mt-1"}>
              <div className={"tag bg-blue"}>{trip.daysInTrip} Days</div>
              <div className={"tag bg-orange"}>${trip.totalCost}</div>
            </div>
          </div>

          <Flights flights={trip.journeys.filter((journey) => journey.travelType == "FLIGHT")}/>
          <Trains trains={trip.journeys.filter((journey) => journey.travelType == "TRAIN")}/>
          <Buses buses={trip.journeys.filter((journey) => journey.travelType == "BUS")}/>
          <Hotels hotels={trip.hotels}/>

        </div>

        <div className={"list-bg"}>
          <div className={"message-box"}>
            <b>Itinerary</b>
            <div className={"float-right"}><FontAwesomeIcon icon={faChevronDown}/></div>
          </div>

          {trip.dailyItinerary.map((unit, index) => (
              <Card key={index} className={`border-radius ${index < props.builtTrip.days-1 ? "mb-3" : ""}`}>
                <div className={"p-3 day-bg border-radius lined-paper"}>

                  <div className={`day-info d-inline-block ${(unit.hotels.length > 0 || sortedActivities(unit).length > 0 || unit.startingPlace !== unit.endingPlace) ? "mb-3" : ""}`}>
                    <b>Day {unit.dayNumber}</b> - {formatDateString(unit.date)}
                  </div>

                  {(unit.startingPlace !== unit.endingPlace && unit.dayNumber < props.builtTrip.days) &&
                  <div className={`${sortedActivities(unit).length === 0 ? "" : "mb-3"}`}>
                      <TravelDetails unit={unit}/>
                  </div>}

                  {sortedActivities.length > 0 && <>
                      <div>
                        {sortedActivities(unit).map((activity, i) => (
                          <>
                            {activity.activityType === "HOTEL" && <div className={`${i < sortedActivities(unit).length - 1 ? "mb-3" : ""}`}>
                                <Hotel key={i} hotel={activity} hotelDetails={props.builtTrip.response.hotels.find(hotel => hotel.dayIndex == unit.dayNumber)} />
                            </div>}
                            {activity.activityType === "RESTAURANT" && <div className={`${i < sortedActivities(unit).length - 1 ? "mb-3" : ""}`}>
                                <Restaurant key={i} restaurant={activity} />
                            </div>}
                            {activity.activityType === "ACTIVITY" && <div className={`${i < sortedActivities(unit).length - 1 ? "mb-3" : ""}`}>
                                <Activity key={i} activity={activity} />
                            </div>}
                          </>
                        ))}
                      </div>
                  </>}

                  {(unit.startingPlace !== unit.endingPlace && unit.dayNumber === props.builtTrip.days) &&
                  <div className={`${sortedActivities(unit).length > 0 ? "mt-3" : ""}`}>
                      <TravelDetails unit={unit}/>
                  </div>}

                </div>
              </Card>
          ))}

        </div>
      </>
  );
}

export default TripResponseView;