import {BuiltTripHotel} from "../../../../types/Types";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHotel, faPlaneUp, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {formatDateString} from "../../../../utils/Util";
import Stars from "../itinerary/hotel/Stars";

interface Props {
  hotels: Array<BuiltTripHotel>
}

function Hotels(props: Props) {
  return (
      <>
        {props.hotels.length > 0 && <div className={"mt-3 p-3 day-bg border-radius lined-paper"}>
          <div className={"day-info d-inline-block mb-3"}><b>Hotels</b> <FontAwesomeIcon icon={faHotel} /></div>
          {props.hotels.map((hotel, index) => (
              <div className={`hotel ${index == props.hotels.length - 1 ? "" : "mb-3"}`}>
                <div className={""}><b>{hotel.name}</b> <small><Stars count={hotel.stars}/></small></div>
                <div>{formatDateString(hotel.date)}</div>
                <div className={"mt-1"}>
                  <div className={"tag bg-blue"}>{hotel.nightsAtHotel} night{hotel.nightsAtHotel == 1 ? "" : "s"}</div>
                  <span></span>
                  <div className={"tag bg-orange"}>${hotel.averageCost}</div>per night
                </div>
                <div style={{float: "right", marginTop: "-28px"}} className={"book-button"}>Reserve <FontAwesomeIcon icon={faUpRightFromSquare}/></div>
              </div>
          ))}
        </div>}
      </>
  )
}

export default Hotels;