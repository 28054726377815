import {BuiltTripActivity} from "../../../../types/Types";
import * as React from "react";
import {faLandmark, faUtensils} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  activity: BuiltTripActivity
}

function Activity(props: Props) {
  const activity = props.activity;
  return (
    <div className={`unit-box activity`}>
      <div className="time itim-regular">{activity.visitingTime}</div>
      <div className="text-lines time-border">
        <div>
          <span><FontAwesomeIcon icon={faLandmark} className={"mr-1"}/></span>
          <span><b>{activity.name}</b></span>
          <div className={"d-inline-block ml-1"}>
            {activity.type && <div className={"tag bg-gray-2"}>{activity.type}</div>}
          </div>
          <div className={"d-inline-block"}>
            {activity.averageCost == "0" && <div className={"tag bg-green"}>FREE</div>}
            {activity.averageCost != "0" && <div className={"tag bg-orange"}>${activity.averageCost}</div>}
          </div>
        </div>
        <div>{activity.description}</div>
        <div>{activity.address}</div>
      </div>
    </div>
  )
}

export default Activity;