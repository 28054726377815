import * as React from "react";
import './../App.css';
import {Col, Form} from "react-bootstrap";
import history from "./../utils/History";
import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword, sendSignInLinkToEmail} from "@firebase/auth";
import { getDocs, where, query, collection } from "@firebase/firestore";
import {db} from "./../firebase/FirebaseProvider";
import set = Reflect.set;

function LoginPage() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        history.push('/');
        return;
      }
    });
  });

  const login = async () => {
    const userCollection = query(collection(db, 'users'), where('email', '==', email.toLowerCase()));
    const userCollectionSnap = await getDocs(userCollection);
    if(userCollectionSnap.size == 0) {
      setError(true);
      setFeedback("Sorry, you don't have an existing user profile, please register first.");
      return;
    }

    if(!password) {
      await loginWithEmail();
    } else {
      await loginWithPassword();
    }
  };

  const loginWithPassword = async () => {



    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email.toLowerCase(), password).then((_) => {
          history.push('/');
        }).catch((error) => {
          setError(true);
          setFeedback("Incorrect credentials, please try again.");
        });
    } catch(e) {
      setError(true);
      setFeedback("Incorrect credentials, please try again.");
    }
  };

  const loginWithEmail = async () => {
    const actionCodeSettings = {
      url: window.location + '/auth/response',
      handleCodeInApp: true
    };
    const auth = getAuth();
    try {
      await sendSignInLinkToEmail(auth, email.toLowerCase(), actionCodeSettings).then(() => {
        window.localStorage.setItem(
            'emailForSignIn',
            email.toLowerCase(),
        );
        setError(false);
        setFeedback("A login link has been emailed to you.")
      });
    } catch(e) {
      setError(true);
      setFeedback("Incorrect credentials, please try again.");
    }
  };

  return (
    <>
      <div className={"message-box mb-2"}>
        <span>No account? Register <a href={"/register"}>here</a>.</span>
      </div>
      {feedback && <div className={`message-box mb-2 alert ${error ? "alert-danger" : "alert-success"}`}>
        <div>{feedback}</div>
      </div>}
      <Form className={"mb-3"}>
        <div className="form-row">
          <Col>
            <Form.Group className="form-element" controlId="email">
              <Form.Label><small><b>Email</b></small></Form.Label>
              <Form.Control
                  type="email"
                  name={"email"}
                  placeholder=""
                  value={email}
                  autoComplete={"username"}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
            </Form.Group>
          </Col>
        </div>
        <div className="form-row">
          <Col>
            <Form.Group className="form-element" controlId="password">
              <Form.Label>
                <small>
                  <b>Password</b>
                  <div style={{fontSize: "9pt"}}>Forgotten it? Leave it blank to receive a login link.</div>
                </small></Form.Label>
                <Form.Control
                  type="password"
                  name={"password"}
                  placeholder=""
                  value={password}
                  autoComplete={"current-password"}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
            </Form.Group>
          </Col>
        </div>
        <div className={"form-row mt-3"}>
          <Col>
            <div className={"float-right"}>
              <div className={"click-button"} onClick={() => login()}>
                <span>Login</span>
              </div>
            </div>
          </Col>
        </div>
      </Form>
    </>
  );
}

export default LoginPage;