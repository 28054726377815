import {BuiltTripActivity, BuiltTripHotel} from "../../../../types/Types";
import * as React from "react";
import Stars from "./hotel/Stars";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHotel} from "@fortawesome/free-solid-svg-icons";

interface Props {
  hotel: BuiltTripActivity
  hotelDetails: BuiltTripHotel | undefined
}

function Hotel(props: Props) {
  return (
      <div className={"hotel unit-box"}>
        <div style={{textAlign: "center"}} className={"time-no-flex itim-regular"}>
          <div>From</div>
          <div>{props.hotel.visitingTime}</div>
        </div>
        <div className={"text-lines time-border"}>
          <div>
            <FontAwesomeIcon icon={faHotel} className={"mr-1"}/>
            <b>{props.hotel.name} </b>
            <small><Stars count={props.hotel.stars}/></small>
          </div>
          <div>{props.hotel.description}</div>
          <div>{props.hotel.address}</div>
          {(props.hotelDetails && props.hotelDetails.nightsAtHotel) && <div>
              You're here for {props.hotelDetails.nightsAtHotel} night{props.hotelDetails.nightsAtHotel == 1 ? "" : "s"}
          </div>}
        </div>
      </div>
  )
}

export default Hotel;