import * as React from "react";
import './../App.css';
import { useEffect } from "react";
import history from "./../utils/History";

function TripsPage() {
  return (
      <>
        <div className={"message-box"}>No saved trips</div>
      </>
  );
}

export default TripsPage;