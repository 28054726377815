import * as React from "react";
import './../App.css';
import {useEffect, useState} from "react";
import {collection, query, getDocs, limit, orderBy, where} from "@firebase/firestore";
import {db} from "../firebase/FirebaseProvider";
import {BuiltTrip} from "../types/Types";
import {calcSecondsBetween, capitalizeAllWords, convertTimestampWithDay} from "../utils/Util";
import {convertTimestampToTime} from "../utils/Util";
import history from "./../utils/History";

function RecentTripsPage() {
  const [recentTrips, setRecentTrips] = useState<Array<BuiltTrip>>([]);

  useEffect(() => {(async () => {
      let recentTrips = new Array<BuiltTrip>();
      const tripsOnceCollection = query(
          collection(db, 'trip-requests'),
          where("error", "==", false),
          orderBy("createdAt", "desc"),
          limit(200),
        );
      const tripsCollectionSnapshot = await getDocs(tripsOnceCollection);
      tripsCollectionSnapshot.forEach((doc) => {
        let tempTrip = doc.data() as BuiltTrip;
        tempTrip.id = doc.id;
        recentTrips.push(tempTrip);
      });
      setRecentTrips(recentTrips);
    })();
  }, []);

  const loadTrip = (id: string) => {
    history.push(`/trip-builder/${id}`)
  };

  return (
      <>
        <div className={"message-box"}>
          <b>Recent Searches</b>
        </div>
        <div className={""} style={{textAlign: "center"}}>
          {recentTrips.length === 0 &&
            <>
            </>
          }
          {recentTrips.length > 0 && <div>
            {recentTrips.map((trip) => (
                <div className={"recent-trip"} onClick={() => loadTrip(trip.id)}>
                    {capitalizeAllWords(trip.locationFrom)} to {capitalizeAllWords(trip.locationTo)}
                    <div>
                      <div className={"tag bg-blue"}>{trip.days} day{trip.days == 1 ? "" : "s"}</div>
                      {trip.budget === "luxury" ? <div className={"tag bg-gold"}>{capitalizeAllWords(trip.budget)}</div> : ""}
                      {trip.budget === "budget" ? <div className={"tag bg-budget"}>{capitalizeAllWords(trip.budget)}</div> : ""}
                      {trip.response && <div className={"tag bg-orange"}>${trip.response.totalCost}</div>}
                    </div>
                  <div>
                    <small>{convertTimestampToTime(trip.createdAt)} {convertTimestampWithDay(trip.createdAt)}</small>
                    {(trip.createdAt && trip.stageOneCompletedAt) &&
                      <>
                        <span> - </span>
                        <small>({calcSecondsBetween(trip.createdAt, trip.stageOneCompletedAt)}s)</small>
                      </>
                    }
                  </div>
                </div>
            ))}
          </div>}
        </div>
      </>
  );
}

export default RecentTripsPage;