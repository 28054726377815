import * as React from "react";
import './../App.css';
import { useEffect } from "react";
import history from "./../utils/History";

function LandingPage() {
  useEffect(() => {
    history.push("/create")
  });

  return (
      <>
      </>
  );
}

export default LandingPage;