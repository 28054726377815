import React, {Component} from "react";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import {Redirect} from "react-router";

interface Props {
  redirectTo: string;
}

interface State {
  authState: number;
}

class RegisterResponse extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      authState: 0
    }
  }

  async componentDidMount() {
    try {
      const auth = getAuth();
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }
        let emailToSignInWith: string = '';
        if (email != null) {
          emailToSignInWith = email;
        }
        await signInWithEmailLink(auth, emailToSignInWith, window.location.href)
            .then((result) => {
              window.localStorage.removeItem('emailForSignIn');
              this.setState({authState: 1});
            });
      } else {
        this.setState({authState: 1});
      }
    } catch (e) {
    }
  }

  render() {
    return (
        <>
          {this.state.authState == 1 && <Redirect to={`/${this.props.redirectTo}`} />}
        </>
    )
  };
};

export default RegisterResponse;
